var url, googleApiKey, wsUrl, wsPrefix, webAppUrl, stripeKey

if (process.env.REACT_APP_QA === 'qa') {
  url = 'https://airtouch.hyperether.com'
  googleApiKey = 'AIzaSyB9fmsHujvPzXiruMlQV1nEXt-VGUKegMI'
  wsUrl = 'wss://socket.hyperether.com'
  webAppUrl = 'https://airtouch-app.hyperether.com'
  wsPrefix = 'airtouchqa'
  stripeKey =
    'pk_test_51NDSHTAPdSh0V6U9C59o7F0n5ezCYrkEIKWdJXL5m6FEDRbHKiG5Hx54K53DzuIo8YyAPjXKusrLEtBoex6cdgff00qI8R6B5T'
} else if (process.env.REACT_APP_PROD === 'prod') {
  url = 'https://api.airtouch.me'
  googleApiKey = 'AIzaSyB9fmsHujvPzXiruMlQV1nEXt-VGUKegMI'
  webAppUrl = 'https://meet.airtouch.me'
  wsUrl = 'wss://socket.hyperether.com'
  wsPrefix = 'airtouchqa'
  stripeKey =
    'pk_live_51OHv2ECW7pqCNm1BlGbGgHMN0VogiIWTWSzfMVwVmiTk8sfa7LG60R203MFqobv6cG0oR904jNJxrUhk0oBUnwUZ00985bUrow'
} else if (process.env.REACT_APP_DEV === 'dev') {
  url = 'http://localhost:3000'
  webAppUrl = 'http://localhost:3002'
  googleApiKey = 'AIzaSyB9fmsHujvPzXiruMlQV1nEXt-VGUKegMI'
  wsUrl = 'wss://socket.hyperether.com'
  wsPrefix = 'airtouchqa'
  stripeKey =
    'pk_test_51NDSHTAPdSh0V6U9C59o7F0n5ezCYrkEIKWdJXL5m6FEDRbHKiG5Hx54K53DzuIo8YyAPjXKusrLEtBoex6cdgff00qI8R6B5T'
} else if (process.env.NODE_ENV === 'development') {
  url = 'http://localhost:3000'
  webAppUrl = 'http://localhost:3002'
  googleApiKey = 'AIzaSyB9fmsHujvPzXiruMlQV1nEXt-VGUKegMI'
  wsUrl = 'wss://socket.hyperether.com'
  wsPrefix = 'airtouchqa'
  stripeKey =
    'pk_test_51NDSHTAPdSh0V6U9C59o7F0n5ezCYrkEIKWdJXL5m6FEDRbHKiG5Hx54K53DzuIo8YyAPjXKusrLEtBoex6cdgff00qI8R6B5T'
} else {
  url = 'http://localhost:3000'
  webAppUrl = 'http://localhost:3002'
  googleApiKey = 'AIzaSyB9fmsHujvPzXiruMlQV1nEXt-VGUKegMI'
  wsUrl = 'wss://socket.hyperether.com'
  wsPrefix = 'airtouchqa'
  stripeKey =
    'pk_test_51NDSHTAPdSh0V6U9C59o7F0n5ezCYrkEIKWdJXL5m6FEDRbHKiG5Hx54K53DzuIo8YyAPjXKusrLEtBoex6cdgff00qI8R6B5T'
}

export default url
export { googleApiKey, wsUrl, wsPrefix, webAppUrl, stripeKey }
